html,
body {
  min-height: 100vh;
  max-height: auto;

}

#root {
  position: relative;
  min-height: 100vh;
  max-height: auto;
  max-width: 100vw;

  /* overflow-x: auto; */

}

.App {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  min-width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-height: 100vh;
  max-height: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  /* background-color: #FFFFE0; */
}

.App-blure {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  min-width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-height: 100vh;
  max-height: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  /* background-color: #FFFFE0; */
}


.header {
  position: fixed;
  top: 0;
  /* left: -8px; */
  background-color: #fff;
  max-height: 100px;
  overflow-y: hidden;
  /* min-height: 120px ; */
  overflow: visible;
  width: 100%;
  /* min-width: calc(100%-8px); */
  z-index: 999;
  /* Ensure the header stays above the content */
}

.navbar .dropdown-menu {
  position: absolute;
  z-index: 1050;

}



.content {
  position: relative;
  min-height: calc(100vh - 120px);
  max-height: auto;
  max-width: 100vw;
  /* top: 15%; */
  margin-top: 90px;
  flex-grow: 1;
  /* padding-top: 70px; Adjust this value to the height of your header */
  overflow-y: auto;
  overflow-x: hidden;
}

.content-blure {
  position: relative;
  min-height: calc(100vh - 120px);
  max-height: auto;
  max-width: 100vw;
  /* top: 15%; */
  margin-top: 120px;
  flex-grow: 1;
  /* padding-top: 70px; Adjust this value to the height of your header */
  overflow-y: hidden !important;
  overflow-x: hidden;
}



@media (max-width: 576px) {

  .content {
    position: relative;
    height: auto;
    /* top: 15%; */
    margin-top: 70px;
    flex-grow: 1;
    max-width: 100vw;
    /* padding-top: 70px; Adjust this value to the height of your header */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .content-blure {
    position: absolute;
    max-height: 100vh;
    /* top: 15%; */
    margin-top: 70px;
    flex-grow: 1;
    max-width: 100vw;
    /* padding-top: 70px; Adjust this value to the height of your header */
    overflow-y: hidden !important;
    overflow-x: hidden;
  }

  .user-info {
    max-width: 100vw;
  }

}


.header-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Adds a soft shadow */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition */
}

.custom-navbar-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Adds a soft shadow */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition */
}


/* *{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
} */

::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  cursor: pointer !important;
}

::-webkit-scrollbar {
  cursor: pointer !important;
  width: 5px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #FED235 !important;
  border: 0px !important;
  cursor: pointer !important;
  background-clip: padding-box !important;
  border-radius: 5px !important;
}

.mdb-table::-webkit-scrollbar {
  cursor: pointer !important;
  height: 4px;

}

.product-table::-webkit-scrollbar {
  cursor: pointer !important;
  height: 4px;
}

.updatetable-responsive::-webkit-scrollbar {
  cursor: pointer !important;
  height: 4px;
}

.smalldevice-space-summary::-webkit-scrollbar {
  cursor: pointer !important;
  height: 4px;
}

.invoice-table-container::-webkit-scrollbar {
  cursor: pointer !important;
  height: 4px;
}




/* Hide the default spinner controls for number inputs */
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} */

/* Remove arrows in Chrome, Safari, and Edge */
.no-arrow-input::-webkit-outer-spin-button,
.no-arrow-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
.no-arrow-input[type=number] {
  -moz-appearance: textfield;
}

.no-arrow-input-table::-webkit-outer-spin-button,
.no-arrow-input-table::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
.no-arrow-input-table[type=number] {
  -moz-appearance: textfield;
}

.custom-placeholder::placeholder {
  font-size: 10px;
  /* Adjust the size as needed */
  color: #888;
  /* Optional: Adjust color if needed */
}



.nav {
  background-color: white;
  padding: 1rem 1rem;
}

#collapsible-nav-dropdown:hover {
  color: #f8ce0b;
}

.product-dropdown:hover {
  color: #f8ce0b;
}

#collapsible-nav-dropdown {
  z-index: 9999999;
}

.dropdown-display {
  z-index: 9999999;
  /* position: absolute; */

}


.img-size {
  position: relative;
  max-width: 200px;
  max-height: 150px;
}

.navbar-link:hover {
  color: #f8ce0b !important;
  text-decoration: none;
}

#search_btn {
  background-color: #02441E;
  color: white;
  border-radius: 0 30px 30px 0;
  width: 80px;
  height: 43px;
}

.input-group {
  display: flex;
  max-width: 600px;
  width: 100%;
}

#search_field {
  height: 2.7rem;
  padding: 1rem;
  border-radius: 30px 0 0 30px;
  margin: 0;
}

#search_field:focus {
  box-shadow: none;
}


.check-input {
  position: relative;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.form-control {
  flex-grow: 1;
}

@media (max-width: 767.98px) {

  .custom-select {
    width: 80px;
    height: 20px;
    font-size: 9px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .custom-select {
    width: 120px;
    height: 25px;
    font-size: 10px;
  }

}


@media (max-width: 767.98px) {

  .dataTables_paginate .pagination {
    display: -xs-flexbox;
    display: flex;
    padding-left: 0;
    max-width: 40%;
    height: 20px;
    list-style: none;
    border-radius: .20rem !important;
  }

  .dataTables_paginate .pagination .page-link {
    position: relative !important;
    font-size: 10px;
    padding: 5px;
    margin-top: 3px;
  }

  .dataTables_info {
    font-size: 1.6vw !important;
  }

  .mdb-datatable-filter .form-control {
    width: 150px;
    max-height: 25px;
    display: flex;
    align-items: center;
    /* Center content vertically */
    justify-content: center;

  }

  .mdb-datatable-filter .form-control::placeholder {
    font-size: 2vw !important;
    display: flex;
    align-items: center;
    /* Center content vertically */
    justify-content: center;

  }

}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;

}


/* #login_btn, */
#view_btn {
  padding: 0.4rem 1.8rem;
  color: black;
}

/* Landing page */
.products_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  background-color: #FED235;
  margin-bottom: 1rem;
  height: 4rem;
  font-size: 1.5rem;
  width: 100%;
  position: relative;
}

.card {
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card-vegetbles {
  height: 80%;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-vegetbles:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.card-title a {
  color: #2e2e2e;
}


.card-title {
  color: #2e2e2e;
}

.card-title-vegetable {
  color: #2e2e2e;
}

.card-title-homepage {
  color: #2e2e2e;
}

.card-title-carousel {
  color: #2e2e2e;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.card-body-vegetable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;
}


.card-text {
  font-size: 1.4rem;
}

.card-img-top {
  width: 100%;
  height: 150px;
}

/* Make the card square */
.square-card {
  position: relative;
  width: 100%;
  /* Responsive width */
  padding-bottom: 100%;
  /* Aspect ratio to make it square */
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  /* Optional background color */

}

.card-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-img-top-vegetable {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  /* Ensure image fits inside */
  min-height: 70%;
  /* Control image height */
  object-fit: contain;
  /* Ensure image scales well */
}

.card-img-top-homepage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  /* Ensure image fits inside */
  min-height: 70%;
  /* Control image height */
  object-fit: inherit;
  border-radius: 5px;
  /* Ensure image scales well */
}

.card-title-vegetable {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 1em;
  margin-top: 5px;
  text-align: center;
}

.card-title-homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 1em;
  margin-top: 5px;
  text-align: center;
}

.card-title-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 1em;
  margin-top: 5px;
  text-align: center;
}

@media (max-width: 768px) {
  .App {
    font-size: 10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    min-width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    max-height: auto;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}


@media (max-width: 768px) {

  .card-title {
    font-size: 1rem;
  }

  .card-title-vegetable {
    font-size: 10px !important;
    margin-top: 2px !important;
  }

  .card-title-homepage {
    font-size: 10px !important;
    margin-top: 8px !important;
  }

  .card-title-carousel {
    font-size: 10px !important;
    margin-top: 2px !important;
  }
}

@media (max-width: 576px) {

  .card-title {
    font-size: 1rem;
  }

  .card-title-vegetable {
    font-size: 12px !important;
    margin-top: 0px !important;
  }

  .card-title-homepage {
    font-size: 11px !important;
    margin-top: 10px !important;
    white-space: nowrap;
  }

  .card-title-carousel {
    font-size: 10px !important;

  }

  .error-message {
    font-size: 6px !important;
  }
}



#view_btn {
  background-color: #fa9c23;
  color: white;
}

.ratings {
  font-size: 1.2rem;
  color: #fdcc0d;
}

#no_of_reviews {
  font-size: 0.85rem;
  color: grey;
  margin-left: 0.5rem;
}

/* Footer */
footer {
  margin-top: 4rem;
  color: grey;
  bottom: 0px;
  width: 100%;
  position: relative;
}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  color: #fdcc0d;
}

.rating-outer::before {
  content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: #f8ce0b;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #FED235;
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #febd69;
  font-weight: bold;
  font-size: 1.4rem;
}

.navbar-link {
  position: relative;
  display: flex;
  color: inherit;
  text-decoration: none;
  align-items: center;
  color: #000;
  font-weight: 400;
}

.cart-container {
  display: flex;
  align-items: center;
}

.cart-icon {
  font-size: 2rem;
  position: relative;
}

#cart_count {
  position: absolute;
  top: -10px;
  /* Adjust as needed */
  right: -3px;
  /* Adjust as needed */
  padding: 0.2em 0.4em;
  font-size: 0.75rem;
  font-weight: bold;
  color: white;
  border-radius: 50%;
}

/* Responsive styles to ensure proper alignment on small devices */
@media (max-width: 768px) {
  .navbar-link {
    justify-content: center;
  }

  .cart-container {
    flex-direction: row;
  }

  .cart-count {
    margin-left: 2rem;
  }


  #cart_count {
    position: absolute;
    top: -12px;
    /* Adjust as needed */
    right: -1px;
    /* Adjust as needed */
    padding: 0.2em 0.4em;
    font-size: 0.75rem;
    font-weight: bold;
    color: white;
    border-radius: 50%;
  }
}


#checkout_btn,
.review-btn {
  background-color: #02441E;
  margin-top: 2rem;
  border-radius: 5rem;
  color: white;
}

#view_order_details {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem !important;
  padding: 2rem 1.5rem !important;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-weight: 700;
}

.wrapper form {
  padding: 2.5rem 3rem;
  margin-top: 1rem;
}

.wrapper form .btn {
  background-color: #02441E;
  color: white;
  margin-top: 2.5rem;
}

.btn-add {
  background-color: #02441E;
  color: white;
  border: none;
  padding: 7px 17px !important;
  font-size: 15px !important;

}

.btn-add-no {
  background-color: #2b6c46;
  color: white;
  border: none;
  padding: 7px 17px !important;
  font-size: 10px !important;

}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-add.focus,
.btn-add:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}



/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

@media (max-width:368px) {
  .step {
    margin: 0;
    border: 0;
    font-size: 8px;
    letter-spacing: 1px;
    line-height: 30px;
    padding: 5px 10px 5px 15px;
    color: grey;
    text-decoration: none;
    cursor: default;
    font-weight: bold;
    float: left;
    height: auto;
  }
}

@media (min-width:368.98px) and (max-width:578px) {
  .step {
    margin: 0;
    border: 0;
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 30px;
    padding: 5px 10px 5px 15px;
    color: grey;
    text-decoration: none;
    cursor: default;
    font-weight: bold;
    float: left;
    height: auto;
  }
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #02441E;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #02441E;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #02441E;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #02441E;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}



/* User Profile */

.avatar-profile {
  height: 13rem;
  width: 13rem;

}

.user-info h5 {
  font-weight: 600;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.3rem;
}

/* Button Styles */
#edit_profile,
#orders,
.change_password {

  width: 100%;
  /* Full width by default */
  max-width: 350px;
  /* Max width for larger screens */
  margin: 10px auto;
  /* Center buttons horizontally and add spacing */
}



/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
  .avatar-profile {
    margin: 0 auto;
    /* Center avatar image */
    display: block;
    height: 7rem;
    width: 7rem;
  }

  .user-info {
    text-align: center;
  }

  .user-info .col-12.col-md-3 {
    margin-bottom: 20px;
  }

  .user-info .col-12.col-md-5 {
    text-align: center;
  }

  .avatar-profile img {
    width: 120px;
    height: 120px;
  }

  #edit_profile,
  #orders,
  .change_password {
    width: 80%;
    /* Adjust width as needed */
  }

  .products_heading {
    font-size: 1rem;
    height: 3rem
  }

}


@media (max-width: 576px) {
  .user-info h4 {
    font-size: 16px;
  }

  .user-info p {
    font-size: 14px;
  }

  .avatar-profile img {
    width: 100px;
    height: 100px;
  }

  .products_heading {
    font-size: 1rem;
    height: 3rem
  }

}

@media (max-width:398px) {
  .products_heading {
    font-size: 1rem;
    height: 3rem
  }

}

#edit_profile {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;

}

.update-btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

.page-link {
  color: #fa9c23;
}

.page-link:hover {
  color: #fa9c23;
}

/* Admin Sidebar */

.sidebar-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  font-weight: normal;
  margin-top: 0;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  background-color: #FED235;
  /* Hidden by default */
  height: 100%;
  left: 0;
  top: 0;
  margin: 0px;
  z-index: 10;
}


#sidebar {
  min-width: 100%;
  max-width: 100%;
  background: #FED235;
  color: black;
  transition: all 0.3s;

}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #fdcc0d;

}

#sidebar ul.components {
  padding: 5px 0px;
  border-bottom: 1px solid #232f3e;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: black;
  text-decoration: none;
}

#sidebar ul li a:hover {
  color: #fdcc0d;
  background: #fff;
}

#sidebar ul li div.dropdown-menu a {
  color: black;

}

#sidebar ul li a i {
  margin-right: 0.3rem;
}

#sidebar ul li.active>a,
a[aria-expanded='true'] {
  color: #fff;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 1rem !important;
  padding-left: 30px !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

.table {
  max-width: 100%;
  position: relative;
  font-size: 12px !important;

}


/* Dashboard */

.card-font-size {
  font-size: 1.5rem;
}


/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #fa9c23;
}

.yellow {
  color: #fdcc0d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  width: 90%;


}

.search-responsive {
  display: flex;
  flex-direction: row;
  /* Change to row to align items horizontally */
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  /* Ensure items wrap on smaller screens */

}

.userproduct-table {
  margin-top: 20px;
  max-width: 90vw;
  font-size: 12px !important;
}

@media (max-width:304px) {
  .userproduct-table {
    margin-top: 20px;
    max-width: 90vw;
    font-size: 12px !important;
    overflow-x: auto;
  }
}

.product-table {
  margin-top: 20px;
  max-width: 90vw;
  font-size: 12px !important;
}

@media (max-width: 1380px) {
  .product-table {
    overflow-x: auto;
    font-size: 7px;
  }
}




.product_container {
  min-width: 80vw;
  position: relative;
}

.table {
  margin-top: 20px;
}

.table thead th {
  background-color: #FED235;
  text-align: center;
  height: 10vh;
  font-weight: bold;
  font-size: 1rem;
  vertical-align: middle;
}

.table tbody td {
  text-align: center;
  font-size: 1.1rem;
  color: black;
  vertical-align: middle;

}

.products {
  white-space: nowrap;
  /* Prevent line breaks between products */
}

.product-item {
  margin-right: 10px;
  /* Add spacing between product items */
  display: inline-block;
  /* Make sure each product is inline */
}


@media (max-width: 767.98px) {

  .table-responsive {
    display: block;
  }

  .table thead th {
    background-color: #FED235;
    text-align: center;
    height: 10vh;
    font-weight: bold;
    font-size: 1rem;
    vertical-align: middle;
  }

  .table-responsive td,
  .table-responsive th {
    display: block;
    width: 100%;
    text-align: left;
  }

  .btn-add {
    padding: 10px 30px !important;
    font-size: 13px !important;
    border: none;
  }

  .btn {
    padding: 5px 10px !important;
    font-size: 10px !important;
  }

  .table-responsive td {
    border: none;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    position: relative;

  }

  .table tbody td {
    text-align: center;
    font-size: 0.8rem;
    color: black;
    vertical-align: middle;

  }

  .table-responsive td:before {
    content: attr(className);
    font-weight: bold;
    display: block;
    /* Hide the label text */
    text-align: left;
  }

  .table-responsive tr {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dee2e6;
    margin-bottom: 1rem;
  }

  .table-responsive tr .serial-number {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .table-responsive thead {
    display: none;
    /* Hide the table header */
  }

}

@media (min-width: 420px) and (max-width: 767.9px) {
  .table-product {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .table-responsive {
    display: block;
    max-width: 80% !important;
  }

  .landingpage-card {
    max-width: 70%;
  }

  .landingpage-card-vegetables {
    max-width: 100%;
  }

  .card {
    height: 100%;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .card:hover {
    transform: translateY(-5px) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .card-title a {
    color: #2e2e2e;
  }

  .card-title {
    color: #2e2e2e;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .card-body-vegetable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .card-text {
    font-size: 1.4rem;
  }

  .card-img-top {
    width: 100%;
    height: 150px;

  }
}

@media (max-width: 422px) {
  .landingpage-card {
    max-width: 80% !important;
  }

  .landingpage-card-vegetables {
    max-width: 40%;
  }

  .card {
    height: 100%;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .card-title a {
    color: #2e2e2e;
  }

  .card-title {
    color: #2e2e2e;
  }


  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .card-body-vegetable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7px;
  }

  .card-text {
    font-size: 1.4rem;
  }

  .card-img-top {
    width: 100%;
    height: 150px;

  }


}

.footer {
  background-color: #f8f8f8;
  padding: 20px 0;
  color: #333;
  position: relative;
  overflow: hidden;
}

@media (max-width: 900.5px) {

  .footer {
    background-color: #f8f8f8;
    padding: 20px 0;
    color: #333;
    position: relative;
    overflow: hidden;
    margin-bottom: 57px;
  }
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 2;
  position: relative;
  padding: 0 10%;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 10px;
  color: #006400;
  font-size: 1.2em;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
  font-size: 0.9em;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 0.8em;
  position: relative;
  z-index: 2;
}

.footer-bottom p {
  margin: 5px 0;
}

.footer-fruit-image-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.1;
  width: 150px;
  z-index: 1;
  height: auto;
}

.footer-fruit-image-left {
  position: absolute;
  top: 10px;
  /* Moved to top */
  left: 10px;
  opacity: 0.1;
  z-index: 1;
  /* Light color effect */
  width: 150px;
  /* Increased size */
  height: auto;
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 5%;
  }

  .footer-section {
    text-align: center;
    margin: 10px 0;

  }

  .footer-section h4 {
    margin-bottom: 8px;
    color: #006400;
    font-size: 1rem;
  }

  .footer-section ul li {
    margin-bottom: 4px;
    font-size: 0.8rem;
  }
}


@media (max-width: 990px) and (min-width: 767.9px) {

  .footer-section h4 {
    margin-bottom: 8px;
    color: #006400;
    font-size: 1em;
  }

  .footer-section ul li {
    margin-bottom: 4px;
    font-size: 0.7em;
  }


}

.confirm-order-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.order-confirm,
#order_summary {
  background-color: #fff;
  padding: 20px !important;
  border-radius: 10px !important;
  margin-top: 22px;
}

.order-summary-values {
  float: right;
}

#checkout_btn {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* viewlist btn */
#viewList_btn {
  background-color: #02441E;
  margin-top: 2rem;
  border-radius: 5rem;
  color: white;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 578px) and (min-width: 290px) {
  #viewList_btn {
    width: 90%;
    height: 20px;
    margin-top: 0px;
    padding: 5px 5px;
    display: flex;
    /* Use flexbox */
    justify-content: center;
    /* Horizontally center the text */
    align-items: center;
    /* Vertically center the text */
    text-align: center;
  }

  #viewList_btn span {
    font-size: 7px;
    /* align-content: center; */
  }
}



/* Ensure dropdown menu shows on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  transform: translate3d(0px, 38px, 0px);
  top: 0;
  left: 0;
  will-change: transform;
}

/* Remove the dropdown arrow */
.nav-item .dropdown-toggle::after {
  display: none;
}

/* Initial styling for the dropdown menu */
.dropdown-menu {
  display: none;
  position: absolute;
  will-change: transform;
  top: 100%;
  left: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

#login_button,
#register_button {
  background-color: #02441E;
}

.about_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: row;
  /* Default: Image and text side by side */
}

.about_text {
  flex: 1 1 300px;
  max-width: 600px;
  margin: 20px;
}

.about_text h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.about_text p {
  font-size: 1rem;
  line-height: 1.5;
}

.about_image {
  flex: 1 1 300px;
  max-width: 600px;
  margin: 20px;
}

.about_image img {
  width: 100%;
  height: auto;
  max-width: 600px;
  border-radius: 10px;
}


@media (max-width: 768px) {
  .about_container {
    flex-direction: column;
    align-items: center;
    padding: 0 17px;
    /* Reduce padding for smaller screens */
  }

  .about_text,
  .about_image {
    margin: 0;
    /* Reset margins for smaller screens */
    min-width: auto;
  }

  .about_text h3 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .about_image {
    order: -1;
    /* Display the image above the text */
  }

  .about_image img {
    max-width: 100%;
    /* Ensure the image takes full width */
  }
}

.footer-section ul li {
  font-weight: bolder;
  text-decoration: none;
}

.footer-section h4 {
  font-weight: bolder;
}

.unstyled-link {
  color: inherit;
  /* Inherit color from parent */
  text-decoration: none;
  /* Remove underline */
}

.unstyled-link:hover {
  color: #f8ce0b;
  text-decoration: none;
}

.terms_container,
.privacy_container,
.refund-policy-container {
  margin: 0% 10% 10%;
  text-align: justify;
}

.terms_container h3,
.privacy_container h3,
.refund-policy-container h3 {
  color: #02441E;
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  margin-bottom: 20px;
}

.terms_container h5,
.privacy_container h5,
.refund-policy-container h5 {
  color: #02441E;
  font-weight: bold;
  margin-bottom: 15px;
}

.terms_container p,
.privacy_container p,
.refund-policy-container p {
  color: #000;
}

.refund-policy-container h6 {
  font-weight: bold;
}

.refund-policy-container li {}

.avatar-initials {
  width: 40px;
  height: 40px;
  background-color: #02441E;
  /* Change this color as needed */
  color: white;
  border-radius: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  transition: border 0.3s ease;
}

/* Add the following CSS to your stylesheet */

/* .payment-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.payment-details {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-details h1 {
  color: #28a745;
}

.payment-details img {
  width: 48px;
  height: 48px;
}

.payment-details table {
  margin-top: 1rem;
  width: 100%;
  border-collapse: collapse;
}

.payment-details table td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
}

.payment-details table td:first-child {
  font-weight: bold;
  text-align: right;
  color: #333;
}

.payment-details table td:last-child {
  text-align: left;
}

.link-container {
  margin-top: 2rem;
}

.orders-link {
  color: #007bff;
  text-decoration: none;
}

.orders-link:hover {
  text-decoration: underline;
} */

.payment-confirm {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}

.payment-details {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-details h1 {
  font-size: 1.5rem;
  /* margin-bottom: 1rem; */
}

.payment-details img {
  width: 40px;
  height: 40px;
  /* margin: 1rem auto; */
}

.payment-details table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.payment-details td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
}


.link-container {
  margin-top: 2rem;
}

.orders-link {
  color: #007bff;
  text-decoration: none;
}

.orders-link:hover {
  text-decoration: underline;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .payment-details {
    padding: 0.5rem;
  }

  .payment-details h1 {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  .payment-details img {
    width: 30px !important;
    height: 30px !important;

  }

  .payment-details td {
    font-size: 0.9rem;
    padding: 0.3rem;
  }

  .orders-link {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }

  .payment-details img {
    width: 50px;
    height: 50px;
  }
}

/* Media Query for very small screens (max-width: 290px) */
@media screen and (max-width: 578px) {
  .payment-confirm {
    padding: 0.5rem;
  }

  .payment-details h1 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .payment-details img {
    width: 20px !important;
    height: 20px !important;
  }

  .payment-details table {
    font-size: 0.8rem;
  }

  .payment-details td {
    padding: 0.2rem;
    font-size: 0.60rem;
  }

  .orders-link {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
  }

  .payment-details img {
    width: 40px;
    height: 40px;
  }
}


/* admin updateorder */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-dialog {
  background: #fff;
  border-radius: 5px;
  overflow: visible;
  max-width: 500px;
  width: 100%;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin: 0;
  font-size: 18px;
}

.close {
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  color: #000;
  opacity: 0.5;
}

.close:hover,
.close:focus {
  opacity: 0.75;
}

.greenColor {
  color: green;
}

.redColor {
  color: red;
}

/* Other styles */
/* .order-details h1 {
  font-size: 24px;
  margin-bottom: 20px;
} */

.order-details p {
  margin-bottom: 10px;
}

.order-details img {
  max-width: 100%;
  height: auto;
}

.form-group {
  margin-bottom: 15px;
}

.userorder_btn {
  padding: 7px 16px;
  font-size: 13px;
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  border-radius: 5px;

}

.btn {
  padding: 7px 16px;
  font-size: 13px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-danger {
  background-color: rgb(241, 45, 45);
  border-color: rgb(241, 45, 45);
  ;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}


.btn-danger:hover {
  background-color: rgb(241, 45, 45);
  border-color: rgb(241, 45, 45);
  ;
}

.delivery-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
  border-radius: 10px;
}

@media (max-width:460px) {
  .delivery-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    margin: 20px 0;
    padding: 0px;
    border-radius: 10px;
  }
}

.detail-column {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  background-color: #fff;
  border-radius: 8px;
}

.detail-row {
  margin-bottom: 15px;
  /* padding: 10px; */
  border-bottom: 1px solid #e0e0e0;
}

.detail-row h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
  color: #333;
}

.detail-row p {
  margin: 0;
  padding-left: 10px;
  color: #555;
}

h5 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #222;
}

@media (max-width: 1200px) {
  .detail-row h6 {
    font-size: 1.1rem;
  }

  .detail-row p {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .detail-row h6 {
    font-size: 1rem;
  }

  .detail-row p {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .detail-row h6 {
    font-size: 0.9rem;
  }

  .detail-row p {
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .detail-row h6 {
    font-size: 0.8rem;
  }

  .detail-row p {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .detail-row h6 {
    font-size: 0.7rem;
  }

  .detail-row p {
    font-size: 0.65rem;
  }
}



/* calender */

.date-input {
  width: 300px;
  height: 30px;
  font-size: 14px;
  padding: 5px;
  box-sizing: border-box;
}

@media (max-width: 767.98px) {
  .date-input {
    width: 120px;
    height: 10px;
    font-size: 9px;
    padding: 9px;
    box-sizing: border-box;
    margin-left: 25px;
  }

  h5 {
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #222;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .date-input {
    width: 200px;
    height: 25px;
    font-size: 10px;
    padding: 9px;
    box-sizing: border-box;
    margin-left: 20px;
  }

  .card-title-vegetable {
    font-size: 12px;
    margin-top: 5px;
  }

  .card-title-homepage {
    font-size: 12px;
    margin-top: 5px;
  }

  .card-title-carousel {
    font-size: 12px;
  }
}


/* stepper */
.stepper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.step {
  text-align: center;
  position: relative;
  flex: 1;
}

.step .step-number {
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  font-size: 14px;
}

.step.active .step-number {
  background-color: #007bff;
}

.step.completed .step-number {
  background-color: #28a745;
}

.step .step-name {
  margin-top: 10px;
}

.step::before {
  content: '';
  position: absolute;
  top: 15px;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.step:first-child::before {
  display: none;
}

.step.completed::before {
  background-color: #28a745;
}

.step.active::before {
  background-color: #007bff;
}



/* updateorder css */
.invoice-table-container {
  margin: 20px 0;
  overflow-x: auto;
}

.updatetable {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  overflow-x: auto !important;
}

.updatetable th,
.updatetable td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  max-width: fit-content;
  white-space: nowrap;
}

.updatetable thead th {
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
  background-color: #FED235;
}

.updatetable-bordered {
  border: 1px solid #dee2e6;
}

.updatetable-bordered th,
.updatetable-bordered td {
  border: 1px solid #dee2e6;
  text-align: center;
}

.updateTableproduct-image {
  width: 50px;
  height: 50px;
}

.updatecheck-input {
  width: 20px;
  height: 20px;
}

.updateTableInput {
  width: 80%;
  height: 80%;

}

@media (max-width: 992px) {
  .updatetable-responsive {
    border: 0;
  }

  .updatetable th,
  .updatetable td {
    font-size: 12px;
    padding: 0.5rem;
  }

  .updateTableproduct-image {
    width: 30px;
    height: 30px;
  }

  .updateTableInput {
    font-size: 12px;
    width: 100%;
    height: 100%
  }

  .updatecheck-input {
    width: 17px;
    height: 17px;
  }
}

@media (max-width: 576px) {
  .updateTableInput {
    font-size: 11px;
    width: 100%;
    height: 100%
  }

  .updatecheck-input {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 350px) and (max-width: 496px) {
  .updateTableInput {
    font-size: 9px;
    width: 100%;
    height: 90%
  }

  .updatecheck-input {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 290px) and (max-width: 349.98px) {
  .updateTableInput {
    font-size: 8px;
    width: 100%;
    height: 90%
  }

  .updatecheck-input {
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 493px) {
  .updatetable-responsive {
    border: 0;
    overflow-x: auto;
  }

  .updatetable {
    width: 100%;
    table-layout: auto;

  }

  .updatetable th,
  .updatetable td {
    font-size: 7px;
    /* padding: 0.1rem; */
    word-wrap: break-word;
  }

}

@media (max-width: 768px) {
  .message-column {
    min-width: 300px;
    min-height: auto;
  }

}


/* jsainvoice css  */


.invoice-container {
  width: 90%;
  /* height: 95vh; */
  margin: auto;
  font-family: 'Times New Roman', Times, serif;
  padding: 20px;
  font-size: 15px;
  font-weight: bold
}

.invoice-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.invoice-header .top-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.invoice-header .top-info div {
  margin-left: 20px;
}

.invoice-header .middle-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .middle-info div {
  flex: 1;
  margin: 0 10px;
}

.invoice-header .middle-info h4 {

  margin-bottom: 10px;
}

.invoice-header .bottom-info {
  display: flex;
  justify-content: space-between;
}

.invoice-header .bottom-info div {
  flex: 1;
  margin: 10px 10px;

}

.bottom-data {
  border: 1px solid #000;
  width: fit-content;
  font-family: Arial, sans-serif;
}

.bottom-data p {
  border-bottom: 1px solid black;
  text-align: center;
  font-weight: bolder;
}

.bottom-data p:last-child {
  border-bottom: none;
}

.invoice-header h4,
.invoice-header h5,
.invoice-header p {
  margin: 0;
}

.invoice-table {
  width: 100%;
  page-break-inside: auto;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #000;
  text-align: center;
}

.invoice-table td {
  font-weight: 800px;
}

.invoice-table tbody tr {
  page-break-inside: avoid;
  /* Prevent row splitting */
}

/* Footer should always appear at the end of the table */
.invoice-table tfoot {
  display: table-footer-group;
  /* Ensure footer is part of the table's final rendering */
}


.signature {
  text-align: right;
  margin-top: 50px;
}

.hidden {
  display: none;
}

.form-select:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}


/*sidebar css */

.sidebar-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 16%;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  overflow-y: auto;
  background-color: #FED235;
  z-index: 10;
}

.sidebar-wrapper.active {
  transform: translateX(0);
}

.sidebar-wrapper .components a,
.sidebar-wrapper .components .nav-link {
  color: #ffffff;
  padding: 10px;
  display: block;
  text-decoration: none;
}

.sidebar-wrapper .components a:hover,
.sidebar-wrapper .components .nav-link:hover {
  background-color: #495057;
}

#sidebarCollapse {
  background: #ffffff;
  color: black;
  border: #FED235;
  outline: none;
  padding: 5px;
  max-height: 50%;
  cursor: pointer;
  position: fixed;
  left: 10px;
  z-index: 1100;
}


.dashboard-sidebar {
  width: 100%;
  display: flex;
}


@media (min-width: 768px) {
  .sidebar-wrapper {
    transform: translateX(0);
  }

  #sidebarCollapse {
    display: none;
  }

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

/* Blur effect styling */
.blur-effect {
  filter: blur(5px);
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
}

/* Disable scrolling when the sidebar is active */
body.no-scroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    width: 50%;
    z-index: 999999;
  }

  #sidebar-close {
    background: #343a40;
    color: red;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 1100;
  }

  .sidebar-space {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.smalldevice-space-dashboard {
  margin-top: 20px;
}



@media (max-width: 767.98px) {
  .admin-dashboard-x {
    font-size: 30px;
  }

  .smalldevice-space-dashboard {
    padding: 30px;
    margin-top: 65px;
  }

  .smalldevice-space {
    padding: 20px;
    margin-top: 65px;
  }

  /* .smalldevice-space-summary {
    padding: 20px !important;
    margin-top: 65px !important;
  } */
}

.smalldevice-space-summary {
  padding: 20px;
  margin-top: 10px;
  overflow: auto;
}

.admin-dashboard-x {
  font-size: 30px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .admin-dashboard-x {
    font-size: 20px;
    margin-left: 20px;
  }

  .logo-admin {
    max-width: 100%;
    height: 70px !important;
    padding: 0px;
  }

  .smalldevice-space-dashboard {
    margin-top: 15px;
  }

}

/* General Styling */
/* body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

h1.admin-dashboard-x {
  font-size: 2rem;
 
}


.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
}


.smalldevice-space-dashboard {
  margin-top: 20px; 
}


.card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card-font-size {
  font-size: 1.2rem;
  font-weight: bold;
}

.card .card-footer {
  font-size: 0.9rem;
}

.card-body {
  padding: 15px;
}


@media (min-width: 1200px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .col-md-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-3,
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .smalldevice-space-dashboard {
    margin-top: 10px;
    padding: 0 10px;
  }

  h1.admin-dashboard-x {
    font-size: 1.5rem;
  }
}


.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer .fa-angle-right {
  font-size: 1.2rem;
}


@media (max-width: 991px) {
  .sidebar-container {
    position: relative;
    width: 100%;
  }
  .row {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .card-font-size {
    font-size: 1rem;
  }
  .card .card-footer {
    font-size: 0.8rem;
  }
} */


@media (width: 768px) {
  .sidebar-wrapper {
    width: 140px;
  }

  .img-size {
    width: 100px;
    height: auto;
  }

  .sidebar-space {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.ordersummary-detail-container {
  max-width: 70vw;
}

.navbar {
  padding: 0px !important;
  margin: 0px !important;

}

@media (max-width: 577px) {

  .custom-navbar {
    min-width: 100%;
    position: relative;
    display: flex;
    justify-content: none;
  }




  /* Adjust Logo Size */
  .custom-navbar .logo {
    width: 150px;
    height: 50px;
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .order-detail-container {
    max-width: 90vw;
  }

  .cart-detail-container {
    max-width: 98vw;

  }

  .ordersummary-detail-container {
    max-width: 80vw;
  }

  /* Align Navbar elements in one row */
  .navbar-brand,
  .cart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 577px) {

  /* Sidebar Navigation */
  .custom-collapse {
    position: fixed;
    left: -50%;
    top: 0;
    width: 50%;
    height: 100vh;
    background-color: #FED235;
    transition: left 0.3s ease;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: hidden;
  }

  .custom-collapse.open {
    left: 0;
  }

  .custom-collapse-close {
    position: absolute;
    left: -50%;
    transition: left 1s ease;
    height: 0vh;
    overflow: hidden;
  }

  /* Apply a blur effect to the rest of the content when the sidebar is open */
  .blur-effect {
    filter: blur(4px);
    pointer-events: none;
  }

  /* Dark overlay background when sidebar is open */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1049;
    display: block;
  }

  .navbar-link-badge {
    display: flex;
    flex: none;
    margin: 0px;
    padding: 0px;
  }

  .sidebar-components {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    margin: 0px;
    padding-top: 2%;
    font-size: 15px;
    font-weight: 400;
    color: #000 !important;
  }

  .navbar-names {
    color: #000;
    margin-bottom: 3px;
  }

  .nav-link {
    text-decoration: none;
  }

  .navbar-names:hover {
    color: blue;
    text-decoration: none;
  }

  /* When the Navbar is open */
  .navbar-collapse.show {
    left: 0;
  }

  .custom-placeholder::placeholder {
    font-size: 10px;
    color: #888;
    text-align: center;
  }

  /* Sidebar links */
  .custom-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-top: 50px;
  }

  /* Sidebar-specific styles */
  .custom-nav .nav-link,
  .custom-nav .nav-item {
    width: 100%;
    text-align: center;
  }

  .navbar-link {
    font-size: 1.2rem;
    padding: 0px 5px;
  }

  .navbar-link:hover {
    color: white;
  }

  .avatar-initials {
    background-color: #02441E;
    color: white;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 35%;

  }

  .sidebar-username {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 600;
  }

  #login_btn {
    margin-top: 35%;
    position: relative;
    margin-bottom: 20px;
    color: black;
  }

  #login_btn :hover {
    color: white;
  }
}

@media (min-width: 577px) {
  .custom-navbar {
    position: relative;
    min-width: 100%;
  }

  .custom-navbar .custom-collapse {
    display: flex !important;
  }

  .custom-navbar .navbar-toggler {
    display: none !important;
  }
}

.custom-navbar .navbar-toggler {
  display: block;
  position: relative;
}

.custom-navbar .custom-collapse.show {
  display: block;
}

@media (min-width: 577px) and (max-width: 992px) {
  .custom-navbar .logo {
    width: 150px;
    height: 50px;
  }

  .admin-logo {
    padding-left: 20px;
  }

  .content {
    position: relative;
    height: auto;
    margin-top: 70px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .custom-placeholder::placeholder {
    font-size: 10px;
    color: #888;
    text-align: center;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .navbar-expand-sm .container {
    margin: 0px;
    min-width: 100%;
    display: flex;
    justify-content: space-between;

  }

}

@media (max-width: 322.9px) {

  .custom-navbar .logo {
    width: 100px;
    height: 40px;
    margin-left: 30px;
  }

}

.dropdown-nav-menu {
  z-index: 9999999999999;
}

@media (min-width: 576px) and (max-width: 650px) {
  .avatar-initials {
    width: 25px;
    height: 25px;
    background-color: #02441E;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
  }

  .cart-icon {
    font-size: 1.5rem;
    position: relative;
  }

  .cart-icon :hover {
    color: #FED235;
  }

  .custom-navbar .logo {
    width: 150px;
    height: 50px;
    margin-left: 2px;

  }
}


@media (min-width: 576px) and (max-width: 992px) {
  .avatar-initials {
    width: 40px;
    height: 40px;
    background-color: #02441E;
    color: white;
    border-radius: 99%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
  }

  .cart-icon {
    font-size: 1.5rem;
    position: relative;
  }

  .cart-icon :hover {
    color: #FED235;
  }

  .btn {
    padding: 7px 15px;
    font-size: 10px;
  }

  .userorder_btn {
    padding: 7px 12px;
    font-size: 10px;
  }

}

@media(max-width: 575.9px) {
  .userorder_btn {
    padding: 5px 9px;
    font-size: 7px;
  }
}

@media (min-width: 1249px) {

  .mdb-table {
    width: 100% !important;
    overflow-x: auto !important;
    display: block;
  }

  .message-column {
    min-width: 300px !important;
    min-height: auto;
  }

  /* Style the table itself */
  table.dataTable {
    width: 100% !important;
    min-width: 70vw !important;
    word-wrap: break-word !important;
    font-size: 1vw !important;
  }

  /* Style the table headers and cells */
  table.dataTable th,
  table.dataTable td {
    padding: 0.5rem !important;
    text-align: center !important;
    white-space: normal !important;
    font-size: 1vw !important;
  }

}


@media (min-width: 768px) and (max-width: 1250px) {

  .mdb-table {
    width: 100% !important;
    display: block;
  }

  /* Style the table itself */
  table.dataTable {
    max-width: 100% !important;
    min-width: 70vw !important;
    font-size: 1vw !important;
  }

  /* Style the table headers and cells */
  table.dataTable th,
  table.dataTable td {
    padding: 0.5rem !important;
    text-align: center !important;
    white-space: normal !important;
    font-size: 1.2vw !important;
  }

}

@media (min-width: 421px) and (max-width: 767.68px) {

  .mdb-table {
    width: 100% !important;
    display: block;
  }

  /* Style the table itself */
  table.dataTable {
    max-width: 100% !important;
    min-width: 70vw !important;
    font-size: 1vw !important;
  }

  /* Style the table headers and cells */
  table.dataTable th,
  table.dataTable td {
    padding: 0.5rem !important;
    text-align: center !important;
    white-space: normal !important;
    font-size: 1.4vw !important;
  }

}


@media (max-width: 420px) {

  .mdb-table {
    width: 95% !important;
    display: block;
    overflow-x: auto;
  }

  .mdb-table thead th {
    height: 20px !important;
    line-height: 20px !important;

  }

  /* Style the table itself */
  table.dataTable {
    max-width: 100% !important;
    font-size: 1vw !important;
  }

  table.dataTable th,
  table.dataTable td {
    padding: 0.5rem !important;
    text-align: center !important;
    white-space: normal !important;
    font-size: 2.6vw !important;

  }

  .dataTables_info {
    font-size: 2vw !important;
  }

  .mdb-datatable-filter .form-control {
    width: 100px;
    max-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .mdb-datatable-filter .form-control::placeholder {
    font-size: 2.5vw !important;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .btn {
    padding: 5px 10px;
    font-size: 10px;
  }

}


@media (min-width:419px) and (max-width:769px) {
  .mdb-table thead th {
    height: 30px !important;
    line-height: 30px !important;
  }

}


@media (min-width: 992.1px) and (max-width: 1202px) {
  .table-product {
    width: 100% !important;

  }

  /* Ensure table shrinks and adjusts based on screen size */
  .table-product table {
    width: 100% !important;
    table-layout: relative;
  }

  /* Ensure the images and inputs don't break the layout */
  .table-product img.img-size {
    max-width: 150px;
    height: auto;
  }

  /* Ensure columns with inputs or text wrap properly */
  .table-product th,
  .table-product td {
    white-space: normal !important;
    word-wrap: break-word !important;
    padding: 0.5rem;
    font-size: 1.4vw !important;
  }

  /* Make inputs responsive */
  .table-product input.form-select {
    width: 100% !important;
    box-sizing: border-box;
  }


}



@media (min-width: 767px) and (max-width: 992px) {
  .table-product {
    width: 100% !important;
  }

  /* Ensure table shrinks and adjusts based on screen size */
  .table-product table {
    width: 100% !important;
    table-layout: relative;
  }

  /* Ensure the images and inputs don't break the layout */
  .table-product img.img-size {
    max-width: 100px;
    height: auto;
  }

  /* Ensure columns with inputs or text wrap properly */
  .table-product th,
  .table-product td {
    white-space: normal !important;
    word-wrap: break-word !important;
    padding: 0.5rem;
    font-size: 1.4vw !important;
  }

  /* Make inputs responsive */
  .table-product input.form-select {
    width: 100% !important;
    box-sizing: border-box;
  }


}

.products-image img {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
}

img {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
}

.sidebar-closed {
  transform: translateX(-100%);
}

.blur-background {
  filter: blur(5px);
  pointer-events: none;
}

.no-interaction {
  pointer-events: none;
}

.logo-admin {
  max-width: 100%;
  height: 90px;
  padding: 0px;
}

/* datacard for analysis  */


.sidebar-fixed {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  z-index: 9999;
  background-color: #fff;
  min-width: 100%;
}

.smalldevice-space {
  padding: 20px;
}

.filter-row {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.date-input {
  max-width: 200px;
  margin-left: 2px;
}

.data-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.data-card {
  flex: 1;
  min-width: 200px;

  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.data-card h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.data-card p {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .data-card {
    min-width: 150px;
  }
}

/* map css */

.mapPopup-fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  padding: 0;
  z-index: 99999999;
}

.mapPopup-body {
  padding: 0;
  height: 100%;
}

.leaflet-container {
  height: 100%;
}

.mapPopup-footer {
  justify-content: space-between;
}

@media (max-width: 768px) {
  .mapPopup-fullscreen {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  .leaflet-container {
    height: calc(100vh - 100px);
  }

  .mapPopup-footer {
    flex-direction: column;
  }

  .mapPopup-footer .btn {
    margin-bottom: 10px;
    width: 100%;
  }
}

.tracking-btn,
.downloadBtn {
  max-height: 35px;
  min-width: 100px;
  color: white;
  background-color: green;
  border: none;
  outline: none;
  font-size: 12px;
  padding: 6px;
  border-radius: 10px;

}

.tracking-btn:hover {
  background-color: #2b6c46;
}


.location-text {
  color: blue;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  margin-left: 40px;
}

.shipping-head {
  font-size: 32px;
}

.map-search-bar {
  position: relative;
  display: flex;
  max-width: 40%;
  height: 40px;
  left: 50%;
  transform: translate(-50%);
  padding-left: 20px;
  font-size: 13px;
  outline: none;
  z-index: 9999999;
  border-radius: 10px;
  border: 1px solid black;
  top: 55px;
}

@media (max-width:768px) {
  .map-search-bar {
    position: relative;
    display: flex;
    /* width: 80%; */
    max-width: 80%;
    height: 40px;
    left: 50%;
    transform: translate(-50%);
    padding-left: 5%;
    font-size: 13px;
    outline: none;
    z-index: 9999999;
    border-radius: 10px;
    border: 1px solid black;
    top: 60px;
  }
}

@media (max-width:576.5px) {
  .location-text {
    color: blue;
    cursor: pointer;
    text-decoration: none;
    font-size: 9px;
  }

  .shipping-head {
    font-size: 22px;
  }
}


.current-location-icon {
  position: absolute;
  bottom: 25%;
  right: 0.6%;
  padding: 10px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

@media (max-height:780px) {
  .current-location-icon {
    position: absolute;
    bottom: 35%;
    right: 0.6%;
    padding: 10px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
}


/* .loader-loading-center {
  height: 70%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.loader-loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  /* Adjust based on the parent container's height */
  min-height: 300px;
  /* Fallback for short containers */
  width: 100%;
}

.loader-parent {
  min-height: 100vh;
}

@media (max-width:767.5px) {
  .loader-loading-center {
    height: 70%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-parent {
    min-height: calc(100vh - 100px);
  }
}

.sidebar-wrapper {
  transition: box-shadow 0.3s ease-in-out;
}

.no-scroll {
  overflow: hidden;
}

.landingpage-card-vegetables {
  margin: 10px;
}

.landingpage-category-card {
  margin: 0px;
}

.card-vegetbles {
  width: auto;
  /* Automatically adjusts based on screen size */
}

/* Styling for devices with width less than 768px (smaller screens) */
@media (max-width: 768px) {
  .card-vegetbles {
    width: 45%;
  }
}

/* Styling for very small devices, like phones */
@media (max-width: 576px) {
  .card-vegetbles {
    max-width: 30%;
    margin-bottom: 15px;
  }
}

.small-toast {
  width: 100%;
  max-width: 500px;
  box-shadow: none;
}

@media (max-width: 600px) {
  .small-toast {
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 14px;
    position: relative;
    display: flex;
    z-index: 9999;
    transition: none !important;
  }
}

.cart_total_text {
  font-size: 24px;
}

.cart_text {
  font-size: 24px;
}

.missed_somethingTxt {
  font-size: 18px;
  white-space: nowrap;
}

/* Media Query for tablets (min-width: 768px) */
@media (max-width: 768px) {
  .cart_text {
    font-size: 20px;
  }

  .confirmorder_name,
  .confirmorder_price,
  .confirmorder_phone,
  .confirmorder_address,
  .order-summary-values {
    font-size: 15px;
  }

  .confirmorder_title,
  .cart_total_text {
    font-size: 18px;
  }

  .missed_somethingTxt {
    font-size: 15px;
  }

}

/* Media Query for mobile devices (max-width: 576px) */
@media (max-width: 576px) {
  .cart_text {
    font-size: 18px;

  }
}

/* Media Query for extra small devices (max-width: 480px) */
@media (max-width: 480px) {
  .cart_text {
    font-size: 14px;
  }

  .shipping_text {
    font-size: 12px;
  }

  .confirmorder_name,
  .confirmorder_price,
  .confirmorder_phone,
  .confirmorder_address,
  .order-summary-values {
    font-size: 12px;
  }

  .confirmorder_title,
  .cart_total_text {
    font-size: 15px;
  }

  .missed_somethingTxt {
    font-size: 10px;
  }
}

@media (max-width: 200px) {
  .cart_text {
    font-size: 12px;
  }
}

/* default address  */


.default-address {
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 990;
  font-size: 12px;
  background-color: #e9ecef;

}

@media (max-width: 576px) {
  .default-address {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 990;
    font-size: 10px;
    background-color: #e9ecef;

  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .default-address {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 990;
    font-size: 12px;
    background-color: #e9ecef;

  }
}

@media (max-width: 322.9px) {
  .default-address {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 990;
    font-size: 10px;
    background-color: #e9ecef;
  }
}

/* address component css */
/* .address-container {
  padding: 20px;
  max-width: 600px;
  min-width: 150px;
  margin: 0 auto;
} */

/* @media (max-width:376px){
  .address-container {
    padding: 20px;
    max-width: 90%;
    min-width: 100px;
    margin: 0 auto;
  }
} */

/* .address-heading {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.address-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  gap: 15px;
}



.address-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
} */

/* @media (max-width:375px){
  .address-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    max-width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .address-info {
    flex: 1;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    color: #555;
    height: auto;
    position: relative;
    max-width: 100%;
  }
  
  
} */

/* .address-formatted {
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: normal;
  white-space: normal; 
  line-height: 1.5; 
}

.address-info {
  flex: 1;
  font-size: 1rem;
  color: #555;
  position: relative;
  max-width: 100%;
} */

.default-badge {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  color: #007BFF;
  margin-left: 10px;
  background-color: #e3f2fd;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.default-badge .MuiSvgIcon-root {
  margin-right: 4px;
}

.address-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.action-icon {
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.2s;
}

.edit-icon:hover {
  color: #4CAF50;
}

/* .edit-icon{
margin-bottom:3px;
  } */

@media (max-width:50px) {
  .edit-icon {}
}

.delete-icon:hover {
  color: #E74C3C;
}

.set-default {
  font-size: 0.8rem;
  color: #007BFF;
  border: 1px solid #007BFF;
  background: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.set-default:hover {
  background-color: #007BFF;
  color: #fff;
}

/* .add-address {
  margin-top: 15px;
  position: relative;
  width: 200px;
  padding: 10px;
  border: none;
  border-radius: 2px;
  border: #000 1px solid;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.add-address:hover {
  background-color: #0056b3;
  color: white;
} */

.add-address {
  display: block;
  margin: 20px auto 0;
  background-color: #cacdcb;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  border: #000 1px solid;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.add-address:hover {
  background-color: #0056b3;
  color: white;
}

/* Responsive */
@media (max-width: 768px) {
  /* .address-card {
    flex-direction: column;
    align-items: flex-start;
  } */

  .address-actions {
    margin-top: 10px;
  }

  .add-address {
    margin-top: 15px;
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 2px;
    border: #000 1px solid;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
}

.back-button {
  display: inline;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s;
}

.back-button:hover {
  color: #007bff;
}

.back-button svg {
  margin-right: 4px;
  font-size: 18px;
}

/* Minimal styling for left-aligned error message */
.error-message {
  color: #e63946;
  font-size: 13px;
  font-weight: 500;
  margin-top: 3px;
  text-align: center;
  display: block;
}

.add-category-btn {
  font-size: 14px;
  padding: 5px 10px;
  color: white;
  display: inline-flex;
  align-items: center;
}

.add-category-btn i {
  margin-right: 8px;
}

/* Responsive input styling */
.responsive-weightChangeInput {
  width: 100%;
  min-width: 30px;
  max-width: 100%;

  /* Small devices (portrait phones, 576px and below) */
  @media (max-width: 575.98px) {
    width: 60%;
  }

  /* Medium devices (landscape phones and small tablets, 576px - 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 70%;
    min-width: 50px;
  }

  /* Larger tablets (768px - 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 60%;
  }

  /* Desktops (992px - 1199px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 70%;
  }

  /* Extra-large desktops (1200px and up) */
  @media (min-width: 1200px) {
    width: 100px;
    max-width: 100px;
  }
}

/* Responsive cart image styling */
.responsive-cartimage {
  display: block;
  height: auto;
  /* Maintain aspect ratio */

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    width: 35px;
  }

  /* Small devices (landscape phones, 576px - 767px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 50px;
  }

  /* Medium devices (tablets, 768px - 991px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 60px;
  }

  /* Large devices (desktops, 992px - 1199px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 70px;
  }

  /* Extra large devices (1200px and up) */
  @media (min-width: 1200px) {
    width: 80px;
  }
}

/* LoginModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-line {
  padding: 1px;
}

.modal-line {
  border-bottom: 1px solid #e9ecef;
}

.login-modal-content {
  background: white;
  padding: 30px;
  margin: 40px;
  border-radius: 8px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.otp-button {
  background: #FED235;
  color: black;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.otp-button:hover {
  background: #FED235;
}

.otp-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: rgb(93, 92, 92);
  color: white;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  margin: 0px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.phone-email-button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  color: #444;
  background-color: #ffffff;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
  outline: none;
}

.phone-email-button.selected {
  background-color: #f0f8ff;
  color: #222;
  border-color: #aacbe0;
  outline: none;
}

.phone-email-button:hover {
  background-color: #e6f2ff;
  color: #222;
  outline: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .phone-email-button {
    font-size: 13px;
    padding: 8px;
  }
}

@media (max-width: 380px) {
  .phone-email-button {
    padding: 3px;
    font-size: 8px;
    padding: 6px;
  }

  .login-modal-content {
    background: white;
    padding: 30px;
    margin: 5px;
    border-radius: 8px;
    max-width: 450px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

/* carousel */
/* Carousel outer container */
.carousel-outer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  margin-top: 30px;
  overflow-x: hidden;
  border-radius: 8px;
}

/* Carousel item container */
.carousel-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  transition: transform 0.3s ease;
  border: 1px solid #ccc;
  border-radius: 8px;

}

/* Carousel item link */
.carousel-item-link {
  text-decoration: none;
  color: inherit;
}

/* Carousel item content */
.carousel-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Carousel item image */
.carousel-item-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.carousel-item-container:hover .carousel-item-image {
  transform: scale(1.05);
}

/* Carousel item name */
.carousel-item-name {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1600px) {
  .carousel-item-image {
    max-width: 180px;
  }
}

@media (max-width: 1199px) {
  .carousel-item-image {
    max-width: 160px;
  }
}

@media (max-width: 767px) {
  .carousel-item-image {
    max-width: 140px;
  }
}

@media (max-width: 575px) {
  .carousel-item-image {
    max-width: 120px;
  }
}


@media (max-width: 575.6px) {
  .card-categories {
    min-width: 60%;
    max-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 450.6px) {
  .card-categories {
    min-width: 80%;
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 320.6px) {
  .card-categories {
    min-width: 95%;
    max-width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



.p-carousel[pr_id_1] .p-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

}

.p-carousel .p-carousel-indicators {
  padding: 0px;
  margin: 0px;
}

.p-carousel-content ul {
  padding: 0px;
  margin: 0px;
}

.p-carousel-indicators .p-link {
  margin-top: 10px;

}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #FED235;
  outline: none;
}

.p-carousel-prev:focus:not(:focus-visible),
.p-carousel-next:focus:not(:focus-visible) {
  outline: none;
}

.p-carousel-prev:focus,
.p-carousel-next:focus {
  outline: none;
}

.p-carousel-prev,
.p-carousel-next {
  outline: none;
}

.p-carousel-prev,
.p-carousel-next {
  border: none;
  padding: 10px;
  border-radius: 10%;
  cursor: pointer;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}

@media (max-width: 768px) {
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 1.5rem;
    height: 0.3rem;
  }
}

.carosel-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid #ddd;
}

.carosel-card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-top: 2px solid #ddd;
}



/* header */
.main-header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 998;
}

.main-header-marquee {
  position: sticky;
  top: 20px;
  /* 30px below the top to accommodate announcements */
  width: 100%;
  z-index: 998;
}


.page-container {
  display: flex;
  flex-direction: column;
}

.page-content {
  display: flex;
  max-width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  flex: 1;
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
}

.MuiBox-root.css-fyxxz9 {
  display: flex;
  justify-content: space-between;
}

.css-qrwp63 {
  flex: 1;
  margin-right: 0px;
  max-width: 95%;
  min-width: 85% !important;
}

.MuiInputBase-root {
  width: 95%;
}

.MuiInputBase-input {
  width: 95%;
}

.cart-input {
  max-width: 70px;
  min-width: 30px;
  border: solid #000 1px;
  border-radius: 3px;
  min-height: 10px;
  margin-left: 3px;
}


@media (max-width: 576.5px) {
  .header-button {
    max-width: 80px !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }

  .cart-icon {
    font-size: 1.3rem;
    position: relative;
  }

  .cart-input {
    max-width: 30px;
    min-width: 30px;
    min-height: 10px;
    border: solid #000 1px;
    border-radius: 3px;
    min-height: 10px;
    margin-left: 3px;
  }
}

.contact-link {
  color: black;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
  /* Optional: Adds underline on hover for better user feedback */
}

@media (max-width: 576.5px) {
  .cart-text {
    font-size: 8px !important;
    margin-top: 0px !important;
  }
}


/* Default styles (mobile-first) */
.product-not-found {
  text-align: center;
  /* margin-top: 75px; */
}

.product-not-found img {
  width: 120px;
  /* Adjust size for mobile */
  height: auto;
  margin-bottom: 10px;
}

.product-not-found p {
  font-size: 14px;
  color: #888;
}

.Cartproduct-not-found {
  text-align: center;
}

/* Extra Small Devices (Portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .product-not-found img,
  .Cartproduct-not-found img {
    width: 100px;
  }

  .product-not-found p,
  .Cartproduct-not-found p {
    font-size: 12px;
  }
}

/* Small Devices (Landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

  .product-not-found img,
  .Cartproduct-not-found img {
    width: 110px;
  }

  .product-not-found p,
  .Cartproduct-not-found p {
    font-size: 13px;
  }
}

/* Medium Devices (Tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .product-not-found img,
  .Cartproduct-not-found img {
    width: 140px;
  }

  .product-not-found p,
  .Cartproduct-not-found p {
    font-size: 14px;
  }
}

/* Large Devices (Desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

  .product-not-found img,
  .Cartproduct-not-found img {
    width: 150px;
  }

  .product-not-found p,
  .Cartproduct-not-found p {
    font-size: 15px;
  }
}

/* Extra Large Devices (Large desktops, 1200px and up) */
@media (min-width: 1200px) {

  .product-not-found img,
  .Cartproduct-not-found img {
    width: 180px;
  }

  .product-not-found p,
  .Cartproduct-not-found p {
    font-size: 20px;
  }
}

.welcome-text {
  font-size: 18px;
  color: #000;
}

.css-imwso6-MuiBottomNavigationAction-label.Mui-selected {
  font-size: 10px !important;
  margin-top: 3px;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
}

.css-imwso6-MuiBottomNavigationAction-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 10px !important;
  opacity: 1;
  -webkit-transition: font-size 0.2s, opacity 0.2s;
  transition: font-size 0.2s, opacity 0.2s;
  transition-delay: 0.1s;
  margin-top: 3px;
}

p.confirmorder_name {
  margin-top: 0;
  margin-bottom: 0px !important;
}

.announcement-marquee {
  height: 20px;
  /* Increased height for better visibility */
  line-height: 20px;
  /* Vertically center the text */
  background-color: #FFEBEE;
  /* Light red background */
  color: #D32F2F;
  /* Dark red text */
  display: flex;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #D32F2F;
  /* Optional border for separation */
}

.announcement-marquee-content {
  display: inline-block;
  /* Ensure it's inline for scrolling */
  animation: scrollText 20s linear infinite;
  /* Smooth scrolling effect */
  white-space: nowrap;
  min-width: 100%;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width:720px) {
  .announcement-marquee-content {
    display: inline-block;
    /* Ensure it's inline for scrolling */
    animation: scrollText 15s linear infinite;
    /* Smooth scrolling effect */
    white-space: nowrap;
    min-width: 100%;
    font-size: 12px;
    font-weight: bold;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-150%);
  }
}

.modal-image-container-banner {
  min-width: 55vw;
  max-width: 55vw;
  max-height: 65vh;
  height: auto;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  overflow: 'hidden';
  position: 'relative';
  background-color: 'transparent';
}

.bannerimg {
  height: auto;
  max-height: 65vh;
  /* max-height: 55vh; */
  min-width: 55vw;
  max-width: 55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  object-fit: cover;
}

@media (max-width:550px) {

  .modal-image-container-banner {
    min-width: 65vw;
    max-width: 65vw;
    max-height: 75vh;
    height: auto;
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    overflow: 'hidden';
    position: 'relative';
    background-color: 'transparent';
  }

  .bannerimg {
    height: auto;
    /* max-height: 65vh; */
    max-height: 75vh;
    min-width: 100%;
    max-width: 65vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    object-fit: cover;
  }
}

/* Back button and filter container */
.header-container {
  display: flex;
  justify-content: space-between;
  /* Align Back button to the left and Filter to the right */
  align-items: center;
  /* Vertically center the items */
  flex-wrap: nowrap;
  /* Prevent wrapping */
  margin-bottom: 1rem;
  gap: 10px;
}

.adminside-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  /* Default font size */
  margin-left: 50px;
}

.adminside-back-button span {
  margin-left: 5px;
}

.filter-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  /* Default font size */
  margin-right: 50px;
}

.filter-container label {
  margin-right: 8px;
}

.filter-container select {
  padding: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  /* Default font size for dropdown */
}


/* Tablets */
@media (max-width: 768px) {

  .adminside-back-button,
  .filter-container {
    font-size: 14px;
    /* Slightly smaller font size */
  }

  .filter-container select {
    font-size: 13px;
    /* Adjust dropdown font size */
  }
}

/* Mobile devices */
@media (max-width: 576px) {

  .adminside-back-button,
  .filter-container {
    font-size: 12px;
    /* Further reduce font size for small screens */
  }

  .filter-container select {
    font-size: 9px;
    /* Smaller font for dropdown */
  }
}

/* Remove margins for very small screens */
@media (max-width: 400px) and (min-width: 290px) {
  .adminside-back-button {
    margin-left: 0;
    /* Remove left margin */
  }

  .filter-container {
    margin-right: 5px;
    /* Remove right margin */
  }
}

/* Extra small devices (below 290px) */
@media (max-width: 290px) {

  .adminside-back-button,
  .filter-container {
    font-size: 8px;
    /* Further reduce font size for small screens */
  }

  .filter-container select {
    font-size: 8px;
    /* Smaller font for dropdown */
  }
}


/* user order list modal */
/* Modal Styles */
.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  overflow: hidden;
}

.custom-modal-dialog {
  max-width: 700px;
  width: 90%;
  margin: auto;
}

.custom-modal-content {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin-top: 30px;
}

.custom-modal-header {
  padding: 1rem;
  background: #FED235;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.custom-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}

.custom-modal-body {
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

/* Table Styles */
.custom-product-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 1rem;
  margin-top: 1rem;
}

.custom-product-table th,
.custom-product-table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
}

.custom-product-table th {
  background-color: #FED235;
  color: black;
  font-weight: bold;
}

.custom-product-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-no-products {
  text-align: center;
  font-size: 1rem;
  color: #999;
}

/* Responsive Design */
@media (max-width: 768px) {

  .custom-modal-dialog {
    max-width: 80%;
    /* margin-right: 70px; */
    margin-top: 80px;
  }

  .custom-modal-title {
    font-size: 1rem;
  }

  .custom-modal-header {
    padding: 0.75rem;
  }

  .custom-modal-body {
    padding: 0.75rem;
  }

  .custom-product-table th,
  .custom-product-table td {
    padding: 0.5rem;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .custom-modal {
    margin-top: 20px;
  }

  .custom-modal-dialog {
    max-width: 80%;
    margin-top: 50px;
  }

  .custom-modal-title {
    font-size: 0.8rem;
  }

  .custom-modal-header {
    padding: 0.65rem;
  }

  .custom-modal-body {
    padding: 0.65rem;
  }

  .custom-product-table th,
  .custom-product-table td {
    padding: 0.3rem;
    font-size: 10px;
  }
}

/* usermodal css */


/* Modal overlay with background blur */
.usermodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.usermodal-container {
  background-color: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  padding: 20px;
  box-sizing: border-box;
}

/* Modal content */
.usermodal-content {
  display: flex;
  flex-direction: column;
}

/* Modal header */
.usermodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.usermodal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.userclose-button {
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

/* Modal form */
.usermodal-body .form-group {
  margin-bottom: 15px;
}

.usermodal-body .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}


.usermodal-body .form-control {
  width: 100%;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.userbtn-submit {
  background-color: #FED235;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Responsive design for smaller screens */
@media (max-width: 576px) {
  .usermodal-container {
    width: 95%;
    padding: 15px;
  }

  .usermodal-title {
    font-size: 1.1rem;
  }

  .usermodal-body .form-control {
    font-size: 0.9rem;
  }

  .userbtn-submit {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px) {
  .usermodal-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .userclose-button {
    font-size: 1.2rem;
  }
}

.customer-details {
  /* Remove card style for customer details */
  padding: 11px 16px 0px 16px;
  /* margin-bottom: 20px; */
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  display: block;
  /* Ensure it's block-level for left alignment */
  width: 100%;
  /* Ensure it takes the full available width */
  max-width: 100%;
  /* Ensure it doesn't overflow */
}

.customer-details h2 {
  font-size: 1.5rem;
  margin-bottom: 12px;
  color: #333;
}

.customer-detail-item {
  margin: 8px 0;
  font-size: 1rem;
  color: #555;
  overflow-wrap: break-word;
  /* Ensure long words break and wrap */
  word-wrap: break-word;
  /* Legacy support */
}

.customer-detail-item strong {
  color: #000;
}

/* Align the customer details to the left and make it responsive */
@media (max-width: 578px) {

  .customer-details h2,
  .address-wrapper h2 {
    font-size: 1rem;
  }

  .customer-detail-item {
    font-size: 0.8rem;
  }
}

@media (max-width: 290px) {

  .customer-details h2,
  .address-wrapper h2 {
    font-size: 0.8rem;
  }

  .customer-detail-item {
    font-size: 0.6rem;
    overflow-wrap: break-word;
    /* Ensure long words break and wrap */
    word-wrap: break-word;
    /* Legacy support */
  }
}


/* Main Container */
.user-address-container {
  max-width: 1200px;
  /* Maximum width for the content */
  margin: 0 auto;
  padding: 0 15px;
  /* Add padding for smaller screens */
}

/* Heading */
.user-address-heading {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Grid Layout for Address Cards */
.user-address-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three cards for larger screens */
  gap: 20px;
}

/* Individual Address Card */
.user-address-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.user-address-card:hover {
  transform: translateY(-5px);
}

/* Card Content */
.user-address-content {
  margin-bottom: 16px;
}

.user-address-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.user-address-phone {
  font-size: 1.1rem;
  margin: 8px 0;
  color: #555;
}

.user-address-text {
  font-size: 1rem;
  color: #666;
}

/* Address Card Actions */
.user-address-controls {
  text-align: right;
}

.user-delete-button {
  cursor: pointer;
  color: #ff4d4f;
  font-size: 1.3rem;
  transition: color 0.3s ease;
}

.user-delete-button:hover {
  color: #e60000;
}

/* No Address Message */
.user-no-address-message {
  text-align: center;
  color: #777;
  font-size: 1rem;
  padding: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Responsive Styles */

/* Medium Screens (Tablets) */
@media (max-width: 992px) {
  .user-address-grid {
    grid-template-columns: repeat(2, 1fr);
    /* Two cards on tablets */
  }

  .user-address-container {
    padding: 0 10px;
  }

  .user-address-heading {
    font-size: 1.5rem;
  }
}

/* Small Screens (Phones) */
@media (max-width: 768px) {
  .user-address-grid {
    grid-template-columns: 1fr;
    /* One card on smaller devices */
  }

  .user-address-card {
    padding: 16px;
    /* Adjust padding for smaller devices */
  }

  .user-address-heading {
    font-size: 1.3rem;
  }

  .user-address-name,
  .user-address-phone,
  .user-address-text {
    font-size: 0.95rem;
  }
}

/* Extra Small Screens */
@media (max-width: 480px) {

  /* .user-address-container{
    max-width: 500px;
  } */
  .user-address-card {
    padding: 12px;
    /* Reduce padding further */
  }

  .user-address-heading {
    font-size: 1.2rem;
  }

  .user-address-name,
  .user-address-phone,
  .user-address-text {
    font-size: 0.85rem;
  }
}

/* order details styling */


.order-id-title,
.order-items,
.delivery-title {
  font-size: 1.7rem;
  /* Default for larger screens */
  font-weight: 500px;
  /* margin-bottom: 16px; */
}

.tracking-btn,
.downloadBtn {
  font-size: 1rem;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.shipping-title {
  font-size: 1.5rem;
  font-weight: 600;
  /* margin-top: 16px; */
  /* margin-bottom: 8px; */
}

.shipping-info {
  font-size: 1rem;
  line-height: 1.5;
}

/* 
.address-formatted {
  margin-top: 8px;
} */

/* Responsive Font Sizes */
@media (max-width: 1200px) {

  .order-id-title,
  .order-items,
  .delivery-title {
    font-size: 1.5rem;
  }

  .shipping-title {
    font-size: 1.3rem;
  }

  .shipping-info {
    font-size: 0.9rem;
  }

  .tracking-btn,
  .downloadBtn {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {

  .order-id-title,
  .order-items,
  .delivery-title {
    font-size: 1.6rem;
  }

  .shipping-title {
    font-size: 1.2rem;
  }

  .shipping-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {

  .order-id-title,
  .order-items,
  .delivery-title {
    font-size: 1.4rem;
  }

  .shipping-title {
    font-size: 1.1rem;
  }

  .shipping-info {
    font-size: 0.75rem;
  }

  .tracking-btn,
  .downloadBtn {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {

  .order-id-title,
  .order-items,
  .delivery-title {
    font-size: 1.2rem;
  }

  .shipping-title {
    font-size: 1rem;
  }

  .shipping-info {
    font-size: 0.7rem;
  }

  .tracking-btn,
  .downloadBtn {
    font-size: 0.7rem;
    padding: 6px 10px;
  }
}

@media (max-width: 400px) {

  .order-container {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-start;
    /* Align content to the left */
  }

  .order-id-title {
    width: 100%;
    text-align: left;
    /* margin-bottom:10px;  */
  }

  .order-buttons {
    display: flex;
    flex-direction: row;
    /* Keep buttons in the same row */
    gap: 10px;
    /* Space between buttons */
    width: 100%;
  }

  .order-items,
  .delivery-title {
    font-size: 1rem;
  }

  .shipping-title {
    font-size: 0.9rem;
  }

  .shipping-info {
    font-size: 0.65rem;
  }

  .tracking-btn,
  .downloadBtn {
    font-size: 0.65rem;
    padding: 5px 8px;
    flex: 1;
    margin-bottom: 8px;
  }
}


@media (max-width: 400px) {
  .status-container b {
    font-size: 0.65rem;
  }
}

@media (max-width: 576px) {
  .status-container b {
    font-size: 0.7rem;
  }
}

@media (max-width: 768px) {
  .status-container b {
    font-size: 0.8rem;
  }
}

/* whatsapp css */
/* Floating WhatsApp Icon */
.whatsapp-float {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  text-decoration: none;
  animation: float 2s infinite ease-in-out;
}

.whatsapp-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.whatsapp-icon:hover {
  transform: scale(1.1);
}

/* Keyframes for floating effect */
/* @keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}
h;
  min-width: 100%;
  max-width: 85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  object-fit: contain;
} */

/* Keyframes for floating effect */
@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

/* search in header length */
@media (min-width:778px) {

  .css-quxd4r-MuiInputBase-root {
    min-width: 90vw;
  }
}


@media (min-width:400px) and (max-width:778.5px) {

  .css-quxd4r-MuiInputBase-root {
    min-width: 83vw;
  }

}

@media (min-width:350px) and (max-width:400.5px) {

  .css-quxd4r-MuiInputBase-root {
    min-width: 73vw;
  }

}

.button-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  justify-content: space-between;
  /* Space out buttons horizontally */
  flex-wrap: wrap;
  /* Ensure buttons wrap to the next line if space is limited */
  gap: 1rem;
  /* Add space between elements */
}

.order-summary-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.order-summary-back-button span {
  margin-left: 0.5rem;
  /* Space between the icon and text */
}

.download-button {
  margin: 10px;
  /* Reset margin */
  background-color: #02441E;
  color: white;
}

.download-button:hover {
  margin: 10px;
  /* Reset margin */
  background-color: #02441E;
  color: white;
}


/* Responsive styling */
@media (max-width: 768px) {
  .button-container {
    flex-direction: row;
    /* Stack buttons vertically on small screens */
    /* align-items: flex-start;  */
  }
}

/* css for login otp box */

.rok_form .rokcta_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
}

.rok_form .rokinput_group {
  display: flex;
  align-items: center;
  column-gap: 10px;
  box-sizing: border-box;
}

/* Main Container */
.paymentlist-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 15px;
  justify-content: space-between;
  /* align-items: flex-start; */
}

/* Date Filters */
.paymentlist-date-filter {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  /* Prevent wrapping of date filters */
}

.start-date,
.end-date {
  display: flex;
  align-items: center;
  /* Align label and input vertically */
  gap: 10px;
  /* Space between label and input */
}

/* Filter Status */
.filter-status {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  /* Prevent wrapping above 420px */
}

/* Input and Select Styling */
.paymentlist-form-control,
.paymentlist-form-select {
  width: 100%;
  max-width: 200px;
  /* Set maximum width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.paymentlist-date-filter label {
  /* display:flex; */
  white-space: nowrap;
}

/* Responsive Styling */

/* Medium Screens (Below 992px) */
@media (max-width: 992px) {
  .paymentlist-container {
    flex-direction: row;
    /* align-items: stretch; */
  }

  .paymentlist-date-filter {
    flex-wrap: nowrap;
    /* Allow wrapping if needed for smaller widths */
    /* gap: 10px; */
  }

  .start-date,
  .end-date {
    flex-wrap: nowrap;
    /* Prevent wrapping of labels and inputs */
  }

  .filter-status {
    flex-wrap: nowrap;
    /* Allow wrapping below 420px */

  }
}

/* Small Screens (Below 768px) */
@media (max-width: 768px) {
  .paymentlist-container {
    padding: 8px;
  }

  .paymentlist-form-control,
  .paymentlist-form-select {
    font-size: 12px;
    padding: 5px;
  }

  .filter-status {
    flex-wrap: nowrap;
    /* Allow wrapping below 420px */

  }
}

/* Extra Small Screens (Below 576px) */
@media (max-width: 576px) {
  .paymentlist-container {
    padding: 5px;

  }

  .paymentlist-date-filter label,
  .filter-status label {
    font-size: 12px;
    white-space: nowrap;
  }

  .paymentlist-form-control,
  .paymentlist-form-select {
    font-size: 12px;
    padding: 6px;
  }

  .filter-status {
    flex-wrap: nowrap;
    /* Allow wrapping below 420px */
    gap: 5px;
    /* Adjust spacing for smaller screens */
  }
}

/* Filter Status Wrap for Screens Below 420px */
@media (max-width: 420px) {
  .paymentlist-date-filter {
    flex-wrap: wrap;
    /* Allow wrapping if needed for smaller widths */
    /* gap: 10px; */
  }

  .paymentlist-date-filter label,
  .filter-status label {
    font-size: 12px;
  }

  .filter-status {
    flex-wrap: nowrap;
    /* Allow wrapping below 420px */
    gap: 5px;
    /* Adjust spacing for smaller screens */
  }

  .paymentlist-form-control,
  .paymentlist-form-select {
    max-width: 100%;
    /* Full width for smaller screens */
    font-size: 12px;
  }
}


.orderReport-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  overflow: hidden;
  /* Prevent modal overflow in any direction */
}

.orderReportmodal-content {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  width: 80%;
  max-width: 90%;
  max-height: 90vh;
  /* Limit modal height to 90% of the viewport */
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /* Enable vertical scrolling */
  overflow-x: hidden;
  /* Disable horizontal scrolling */
}

.orderReportmodal-content h2 {
  text-align: center;
  margin-bottom: 1rem;
  word-wrap: break-word;
  /* Prevent text overflow */
}

.orderReportmodal-content .btn-secondary {
  display: block;
  margin: 1rem auto 0;
}

.orderReportmodal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}


/* Responsive Design */
@media (max-width: 768px) {
  .orderReportmodal-content {
    width: 90%;
    padding: 1rem;
  }

  .orderReportmodal-content h2 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .orderReportmodal-content {
    width: 95%;
    padding: 0.8rem;
  }

  .orderReportmodal-content h2 {
    font-size: 1rem;
  }

  .dataTables_length label {
    font-size: 0.6rem;
  }
}

.packEdit {
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

@media (min-width:768px) and (max-width:1100px) {
  .custom-modal {
    margin-left: 70px;
  }

  .custom-modal-dialog {
    max-width: 600px;
    width: 90%;
    margin: auto;
  }
}

@media (min-width:1100px) {
  .custom-modal {
    margin-left: 100px;
  }
}

.small-toast-input {
  width: 100%;
  max-width: 500px;
  box-shadow: none;
}

@media (max-width: 768px) {
  .small-toast-input {
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 14px;
    position: relative;
    display: flex;
    z-index: 9999;
    top: 70px;
    transition: none !important;
  }
}

.MuiBox-root .css-14xx94a {
  min-width: 87vw;
}

@media (min-width:400px) and (max-width:778.5px) {

  .MuiBox-root .css-14xx94a {
    min-width: 83vw;
  }

}

@media (min-width:350px) and (max-width:400.5px) {

  .MuiBox-root .css-14xx94a {
    min-width: 73vw;
  }

}

@media (min-width:250px) and (max-width:350.5px) {

  .MuiBox-root .css-14xx94a {
    min-width: 63vw;
  }
}

/* NewDiscount.css */

.discount-container {
  margin-top: 30px;
}

.discount-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.discount-back-button span {
  margin-left: 5px;
  font-size: 16px;
}

h2,
h3 {
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
}

.discount-select {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  padding: 12px;
  margin-bottom: 25px;
}

.discount-select .select__control {
  border: 1px solid #bdc3c7 !important;
  border-radius: 6px;
}

.discount-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  margin-bottom: 25px;
}

.discount-btn {
  padding: 12px 22px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.discount-btn:hover {
  background-color: #2980b9;
}

.discount-table th,
.discount-table td {
  padding: 15px;
  text-align: center;
}

.discount-table input {
  width: 70px;
  padding: 8px;
  text-align: center;
}

.discount-table .btn-select {
  padding: 6px 12px;
  background-color: #16a085;
  border-radius: 4px;
}

.discount-table .btn-select:hover {
  background-color: #1abc9c;
}

/* new discount page */
.discount-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.discount-type {
  max-width: 25%;
  padding: 10px;
}

.discount-category {
  max-width: 40%;
  padding: 10px;
}

.discount-input-search {
  max-width: 35%;
  padding: 10px;
}

.discount-input {
  max-height: 40px;
}

.discount-button {
  max-height: 40px;
  margin-left: 2px;
  border-radius: 5px;
}


@media (max-width:1000px) {
  .discount-filters {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .discount-type {
    max-width: 98%;
    min-width: 98%;
    padding: 10px;
  }

  .discount-category {
    max-width: 98%;
    min-width: 98%;
    padding: 10px;
  }

  .discount-input-search {
    max-width: 98%;
    min-width: 98%;
    padding: 10px;
  }

  .discount-input {
    max-height: 40px;
    max-width: 80%;
    min-width: 80%;
  }

  .discount-button {
    max-height: 40px;
    max-width: 18%;
    min-width: 18%;
    margin-left: 2px;
    border-radius: 5px;
  }
}

.input-discount-mdb {
  max-width: 100px;
  width: 100%;
  box-sizing: border-box;
}

.discount-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.discount-table th,
.discount-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.discount-table th {
  background-color: #f4f4f4;
}

.input-discount {
  width: 80px;
  margin: 0 auto;
  text-align: center;
}

.discount-submit-btn-section {
  margin-top: 20px;
  min-height: 40px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.discount-table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.discount-input-search {
  float: inline-end;
}

.discount-table-search {
  float: inline-end;
  max-width: 150px;
}

.discount-global-section {
  max-width: 50%;
  float: right;
}


@media (max-width:1000px) {
  .discount-global-section {
    max-width: 100%;
    float: right;
  }
}

.table td.products-image {
  padding: 0px;
}

td.products-image {
  padding: 0px;
}

@keyframes scrollEffect {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes scrollDown {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes zigzagEffect {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* Wallet Container */
.wallet-container {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  transition: all 0.3s ease;
}

/* Checkbox Label */
.wallet-container label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

/* Checkbox Styling */
.wallet-container input[type="checkbox"] {
  accent-color: #28a745;
  /* Green for checked state */
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

/* Wallet Usage Info */
.wallet-details {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.wallet-details p {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}

.wallet-details .order-summary-values {
  font-weight: 600;
  color: #28a745;
  /* Highlighted Green */
}

/* Divider */
.wallet-container hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

/* Input Field for Wallet Amount */
.wallet-details input[type="number"] {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 0.9rem;
  color: #333;
  margin-left: 10px;
  text-align: center;
}

.wallet-details input[type="number"]:focus {
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.3);
}

/* Total Payable Section */
.pay-online {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.pay-online span {
  font-size: 1rem;
  margin-left: 8px;
  color: #007bff;
  /* Highlighted Blue */
}

/* Hover Effects */
.wallet-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

#check_cart_item {
  color: green;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#cancel_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#edit_cart_item {
  color: blue;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}


.refund-h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.refund-para {
  font-size: 1rem;
  color: #555;
  margin: 8px 0;
}

.refund-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #444;
  cursor: pointer;
  margin-bottom: 10px;
}

.modal-body input[type="radio"] {
  margin-right: 10px;
  accent-color: #007bff;
  /* Change to desired color */
  transform: scale(1.2);
}

.refund-details {
  margin-top: 15px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.refund-details p {
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
}

.modal-body .refund-details p strong {
  font-weight: bold;
  color: #007bff;
}

/* Wallet Container */
.wallet-container {
  position: relative;
  max-width: 96%;
  min-width: 96%;
  margin: 20px auto;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

/* Wallet Header */
.wallet-header {
  text-align: start;
  margin-bottom: 20px;
}

.wallet-header h2 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 5px;
}

.wallet-header p {
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
}

/* Filter Section */
.filter-section {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filter-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.filter-group label {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  color: #333;
}

.filter-group select {
  width: 100%;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Transactions Section */
.transactions-section ul {
  list-style-type: none;
  padding: 0;
}

.transactions-section li {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.transaction-info {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
}

.transaction-meta {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.transaction-meta span {
  margin-right: 10px;
}

.no-transactions {
  text-align: center;
  font-size: 1rem;
  color: #999;
  margin-top: 20px;
}

@media (max-width:500px) {
  .filter-group {
    flex-direction: column;
    justify-content: start;
  }

  .filter-group label {
    flex-direction: column;

  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .wallet-container {
    padding: 10px;
  }

  .wallet-header h2 {
    font-size: 1.3rem;
  }

  .wallet-header p {
    font-size: 1rem;
  }

  .transactions-section h3 {
    font-size: 1.2rem;
  }

  .filter-group {
    gap: 10px;
    /* flex-direction: row; */
  }

  .filter-group label {
    font-size: 0.8rem;
  }

  .filter-group select {
    padding: 6px;
    font-size: 0.8rem;
  }

  .transactions-section li {
    padding: 10px;
  }

  .transaction-info {
    font-size: 0.9rem;
  }

  .transaction-meta {
    font-size: 0.8rem;
  }

  .transaction-meta span {
    margin-right: 5px;
  }


}

@media (max-width:350px) {
  .transactions-section li {
    flex-direction: column;
    align-items: flex-start;
  }

  .transaction-info,
  .transaction-meta {
    margin-top: 5px;
  }

  .transactions-section li {
    padding: 8px;
  }

  .transaction-info {
    font-size: 0.8rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .transaction-meta {
    font-size: 0.7rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .transaction-meta span {
    margin-bottom: 5px;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

/* Transaction Info Styling */
.transactions-section ul {
  list-style-type: none;
  padding: 0;
}

.transaction-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transaction-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #777;
}

/* Amount Styling */
.amount {
  font-weight: bold;
}

/* Status Classes */
.transaction-status.status-pending {
  color: orange;
}

.transaction-status.status-success {
  color: green;
}

.transaction-status.status-failed {
  color: red;
}

/* Amount Classes */
.amount.debit-success {
  color: red;
}

.amount.credit-success {
  color: green;
}

.amount.pending {
  color: orange;
}

/* No transactions found */
.no-transactions {
  color: #999;
}

.hover-container {
  outline: 2px solid transparent;
  transition: border 0.3s ease;
  box-sizing: border-box;
  padding: 10px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

}

.hover-container:hover {
  outline: 2px solid black !important;
  border: none;
  /* width:80px; */

}


.hover-container-fresh {
  border: 2px solid transparent;
  transition: border 0.3s ease;
  box-sizing: border-box;
  padding: 12px;
  padding-left: 25px;
  padding-right: 25px;

}

.hover-container-fresh:hover {
  border: 2px solid black;
  /* width:80px; */

}

.css-15znf79-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 0px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

}

.login-btn-header {
  padding: 19px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

@media (min-width:1000px) {
  .search-header {
    margin-right: 20px;
  }
}

.column-layout {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;
}

/* Base Table Styling */
.productEdit-table {
  width: 95%;
  border-collapse: collapse;
  overflow-x: auto;
  /* display: block; */
}

/* Table Headers */
.productEdit-table thead th,
.productEdit-table tbody td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 14px;
}

/* Images in Table */
.productEdit-table img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 5px;
}

/* Input Fields */
.no-arrow-input-table {
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* Base Styling */
.normaltable-search {
  width: 100%;
  max-width: 300px;
  /* Default width */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: block;
  margin: auto;
}

/* Responsive Design */

/* Tablets & Smaller Screens */
@media (max-width: 768px) {
  .productEdit-table {
    width: 95%;
    /* margin: 10px 10px; */
    margin-left: 10px;
    margin-right: 10px;

  }

  .productEdit-table thead th,
  .productEdit-table tbody td {
    font-size: 13px;
    padding: 4px;
  }

  .no-arrow-input-table {
    width: 55px;
    font-size: 13px;
    text-align: center;
  }

  .normaltable-search {
    max-width: 250px;
    font-size: 14px;
    padding: 6px;
    margin-left: 10px !important;
  }
}

/* Mobile Devices */
@media (max-width: 480px) {

  .productEdit-table thead th,
  .productEdit-table tbody td {
    font-size: 10px;
    padding: 3px;
  }

  .no-arrow-input-table {
    width: 45px;
    font-size: 10px;
    text-align: center;
  }

  .normaltable-search {
    max-width: 200px;
    font-size: 12px;
    padding: 5px;
  }
}

/* Very Small Screens (Up to 290px) */
@media (max-width: 290px) {
  .productEdit-table {
    font-size: 5px;
  }

  .productEdit-table thead th,
  .productEdit-table tbody td {
    padding: 2px;
  }

  .productEdit-table img {
    width: 15px;
    height: 15px;
  }

  .no-arrow-input-table {
    width: 30px;
    text-align: center;
  }

  .normaltable-search {
    max-width: 100px;
    font-size: 10px;
    padding: 4px;
  }

}

/* 🌟 Default: Large Screens (Above 900px) */

@media (min-width: 899.4px) {
  #checkout_button_container {
    display: none;
  }

  #order_summary #checkout_btn {
    display: block;
    width: 100%;
    height: 45px;
    font-size: 16px;
    background-color: #02441E;
    color: white;
    border: none;
    border-radius: 5rem;
    cursor: pointer;
  }

  #order_summary #checkout_btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

/* 🌟 Small Screens (Below 900px) */
@media (max-width: 899.5px) {
  #order_summary #checkout_btn {
    display: none;
    /* Hide button inside order summary */
  }

  #checkout_button_container {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    background: white;
    /* padding: 15px; */
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000;
    border-top: 1px solid #ddd;
  }

  #checkout_button_container #checkout_btn {
    width: 90%;
    max-width: 400px;
    height: 45px;
    font-size: 16px;
    background-color: #02441E;
    color: white;
    border: none;
    border-radius: 5rem;
    cursor: pointer;
  }

  #checkout_button_container #checkout_btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

/* 🌟 Below 782px (Tablet Mode) */
@media (max-width: 782px) {
  #checkout_button_container {
    bottom: 56px;
    /* Adjust height above footer */
    /* padding: 12px; */
  }

  #checkout_button_container #checkout_btn {
    height: 40px;
    font-size: 15px;
  }
}

/* 🌟 Below 420px (Small Mobile) */
@media (max-width: 420px) {
  #checkout_button_container {
    bottom: 56px;
  }

  #checkout_button_container #checkout_btn {
    width: 95%;
    height: 38px;
    font-size: 12px;
  }
}

/* ✅ Large Screens (Above 900px) */
@media (min-width: 899.4px) {
  #proceed_btn_container {
    display: none;
  }

  .wallet-container-modal {
    display: block;
    /* Show wallet in order summary */
  }

  #wallet_fixed_container {
    display: none;
    /* Hide wallet at bottom */
  }

  #proceed_btn {
    display: block;
    width: 100%;
    height: 45px;
    font-size: 16px;
    background-color: #02441E;
    color: white;
    border: none;
    border-radius: 5rem;
    cursor: pointer;
  }

  #proceed_btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

@media (max-width: 899.5px) {

  /* Hide default proceed button initially */
  #proceed_btn {
    display: none;
  }

  /* Main container that expands when wallet is used */
  #proceed_btn_container {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    background: white;
    padding: 15px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000;
    border-top: 1px solid #ddd;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  /* Use Wallet checkbox inside proceed container */
  #wallet_fixed_container {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 500px;
    width: 100%;
  }

  #wallet_fixed_container label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    cursor: pointer;
  }

  #wallet_fixed_container input[type="checkbox"] {
    accent-color: #28a745;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    cursor: pointer;
  }

  /* Wallet details appear within proceed container */
  .wallet-container-modal {
    background: #fff;
    border-top: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    display: none;
    transition: max-height 0.3s ease-in-out;
    max-width: 500px;
    min-width: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  /* Show when wallet is selected */
  .wallet-container-modal.show {
    display: block;
  }

  .wallet-container-modal h5 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }

  .wallet-container-modal p {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallet-container-modal input[type="number"] {
    width: 100%;
    max-width: 100px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin-left: 10px;
  }

  .wallet-container-modal .pay-online {
    font-weight: bold;
    color: #d9534f;
    margin-top: 10px;
  }

  /* Proceed button */
  #proceed_btn_fixed {
    width: 100%;
    max-width: 500px;
    height: 45px;
    font-size: 16px;
    background-color: #02441E;
    color: white;
    border: none;
    border-radius: 5rem;
    cursor: pointer;
    margin-top: 10px;
  }

  #proceed_btn_fixed:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

@media (max-width: 782px) {
  #proceed_btn_container {
    padding: 12px;
  }

  .wallet-container-modal h5 {
    font-size: 16px;
  }

  .wallet-container-modal p {
    font-size: 13px;
  }

  .wallet-container-modal input[type="number"] {
    max-width: 80px;
    font-size: 12px;
    padding: 6px;
  }

  #wallet_fixed_container {
    padding: 10px;
    height: 40px;
    width: 100%;
    max-width: 500px;
  }

  #proceed_btn_fixed {
    font-size: 14px !important;
    height: 40px;
    width: 100%;
  }
}

/* ✅ Styles for screens below 420px */
@media (max-width: 420px) {
  #proceed_btn_container {
    padding: 10px;
  }

  .wallet-container-modal h5 {
    font-size: 14px;
  }

  .wallet-container-modal p {
    font-size: 12px;
  }

  .wallet-container-modal input[type="number"] {
    max-width: 70px;
    font-size: 11px;
    padding: 5px;
  }

  #wallet_fixed_container {
    padding: 8px;
    height: 35px;
    width: 100%;
  }

  #proceed_btn_fixed {
    font-size: 12px !important;
    height: 35px;
    width: 100%
  }

  #wallet_fixed_container label {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
  }
}